import { useEffect } from "react";
import { Provider } from "react-redux";
import { isSSR } from "helpers/ssr";
import { useRouter } from "next/router";

import useMemory from "hooks/useMemory";
import { useStore } from "../hooks/useStore";
import { MODE } from "../config/constants";
import { init } from "../providers/init";
import PasswordProtection from "../providers/password/PasswordProtection";
import AuthProvider from "../providers/Auth";

import "../styles/globals.scss";

init();

const getPrevPageStr = (str = "") => {
  return str.length > 1 ? str.slice(0, str.length - 1) : str;
};

function MyApp({ Component, pageProps, err }) {
  const store = useStore();
  const router = useRouter();
  const memory = useMemory();

  const footerUrlArr = Array.isArray(pageProps?.footerMenu)
    ? pageProps.footerMenu.map((item) => item?.url)
    : [];

  useEffect(() => {
    function unhandledrejectionHandler(event) {
      const isArrOrStr =
        event?.reason &&
        (Array.isArray(event?.reason) || typeof event?.reason === "string");
      if (!isArrOrStr) return;

      const isPlayExists = event?.reason?.includes?.("play");
      const isNotSupportedExists =
        event?.reason?.includes?.("NotSupportedError");
      if (isPlayExists || isNotSupportedExists) {
        console.log(event?.reason);
        return;
      }
      throw event?.reason;
    }

    if (process.env.gtm && !isSSR()) {
      for (
        let index = 0;
        index < document.getElementsByClassName("adsbygoogle").length;
        index++
      ) {
        try {
          (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        } catch (e) {}
      }
      window.addEventListener("unhandledrejection", unhandledrejectionHandler);
    }

    return () => {
      window.removeEventListener(unhandledrejectionHandler);
    };
  }, []);

  useEffect(() => {
    const prevPage = memory.get("prev_page") || "";
    if (
      footerUrlArr?.includes(getPrevPageStr(prevPage)) &&
      router.asPath === "/"
    ) {
      router.push("/#copyright");
    }
    return () => memory.set("prev_page", router.asPath);
  });

  const main = (
    <AuthProvider>
      <Component {...pageProps} err={err} />
    </AuthProvider>
  );

  return (
    <Provider store={store}>
      {MODE === "production" ? main : <PasswordProtection children={main} />}
    </Provider>
  );
}

export default MyApp;
