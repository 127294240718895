import { isSSR } from "../helpers/ssr";

let initStore;

if(process.env.NODE_ENV !== 'production') {
  initStore = require("./store.dev");
} else {
  initStore = require("./store.prod");
}
initStore = initStore.default;


export const initializeStore = (preloadedState) => {
  let _store;
  let store;

  if(isSSR()) {
    _store = initStore(preloadedState)
  } else {
    store = window.store;
    _store = window.store ?? initStore(preloadedState)
  }

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (isSSR()) return _store
  // Create the store once in the client
  if (!store) window.store = _store

  return _store
}
