import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';

function Auth({ children }) {
  const auth = useAuth();

  useEffect(() => {
    auth.init();
  }, []);

  return children;
}

export default Auth;
