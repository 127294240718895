import React from "react";

import styles from "./styles.module.scss";

const logoWrapperStyles = {
  position: "relative",
  width: "150px",
  height: "75px",
  margin: "0 auto",
  objectFit: "contain",
};

const absoluteStyles = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const stripeStyles = {
  display: "block",
  width: "50px",
  height: "3px",
  backgroundColor: "#b20000",
  transformOrigin: "left",
};

const stripeWrapperStyles = {
  position: "absolute",
  top: 0,
  right: 0,
  overflow: "hidden",
  height: "21px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "transparent",
};

const stripes = Array.from({ length: 4 }, (_, idx) => (
  <span
    key={idx}
    className={styles.stripe}
    style={{ ...stripeStyles, animationDelay: `${idx * 0.2}s` }}
  />
));

function Loader({ absolute = false, ...props }) {
  const wrapperStyles = absolute
    ? { ...logoWrapperStyles, ...absoluteStyles }
    : { ...logoWrapperStyles };

  return (
    <div style={wrapperStyles} {...props} role="image">
      <img alt="logo" src="/images/logo.png" width={150} height={75} />
      <div style={stripeWrapperStyles}>{stripes}</div>
    </div>
  );
}

export default Loader;
