export const MOBILE_MAX_SIZE = 576;
export const TABLET__MAX_SIZE = 992;

const initialState = {
  innerWidth: null,
};

export const SIZE_CHANGE = 'SIZE CHANGE';

const devicesStore = (state = initialState, action) => {
  switch (action.type) {
    case SIZE_CHANGE:
      return {
        innerWidth: action.data,
      };

    default:
      return state;
  }
};

export default devicesStore;
