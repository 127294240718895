const initialState = {
  isFetching: false,
  user: null,
  unauthorized: false,
  url: "",
  isEmailChanged: false,
  isPhoneChanged: false,
  commentData: null,
};

export const AUTH_GET_USER_REQUEST = "AUTH_GET_USER_REQUEST";
export const AUTH_GET_USER_RESPONSE = "AUTH_GET_USER_RESPONSE";
export const AUTH_GET_USER_REJECT = "AUTH_GET_USER_REJECT";

export const PROFILE_SAVE_REQUEST = "PROFILE_SAVE_REQUEST";
export const PROFILE_SAVE_RESPONSE = "PROFILE_SAVE_RESPONSE";
export const PROFILE_SAVE_REJECT = "PROFILE_SAVE_REJECT";

export const NOTIFICATION_SETTING_REQUEST = "NOTIFICATION_SETTING_REQUEST";
export const NOTIFICATION_SETTING_RESPONSE = "NOTIFICATION_SETTING_RESPONSE";

export const EMAIL_CHANGE_REQUEST = "EMAIL_CHANGE_REQUEST";
export const PHONE_CHANGE_REQUEST = "PHONE_CHANGE_REQUEST";

export const FIELD_CHANGE_RESPONSE = "FIELD_CHANGE_RESPONSE";

export const PROFILE_UPDATE_AVATAR = "PROFILE_UPDATE_AVATAR";

export const PROFILE_DELETE_RESPONSE = "PROFILE_DELETE_RESPONSE";

export const PROFILE_TOGGLE_FETCHING = "PROFILE_TOGGLE_FETCHING";

export const NEW_COMMENT_RESPONSE = "NEW_COMMENT_RESPONSE";

const authStore = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_GET_USER_REQUEST:
      return {
        ...state,
        user: null,
        url: "",
        isFetching: true,
      };
    case AUTH_GET_USER_RESPONSE:
      return {
        ...state,
        user: action.data.user,
        isFetching: false,
        unauthorized: false,
      };
    case AUTH_GET_USER_REJECT:
      return {
        ...state,
        user: null,
        url: action.data.url + "",
        isFetching: false,
        unauthorized: true,
      };
    case NOTIFICATION_SETTING_REQUEST:
    case PROFILE_SAVE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case NOTIFICATION_SETTING_RESPONSE:
      return {
        ...state,
        isFetching: false,
      };
    case PROFILE_SAVE_RESPONSE:
      return {
        ...state,
        user: action.data.user,
        isFetching: false,
      };
    case PROFILE_UPDATE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          picture: action.data.url,
        },
        isFetching: false,
      };
    case PROFILE_SAVE_REJECT:
      return {
        ...state,
        isFetching: false,
      };
    case PROFILE_DELETE_RESPONSE:
      return {
        ...state,
        user: null,
      };
    case PROFILE_TOGGLE_FETCHING:
      return {
        ...state,
        isFetching: !state.isFetching,
      };
    case EMAIL_CHANGE_REQUEST:
      return {
        ...state,
        isEmailChanged: true,
      };
    case PHONE_CHANGE_REQUEST:
      return {
        ...state,
        isPhoneChanged: true,
      };
    case FIELD_CHANGE_RESPONSE:
      return {
        ...state,
        isPhoneChanged: false,
        isEmailChanged: false,
      };
    case NEW_COMMENT_RESPONSE:
      return {
        ...state,
        commentData: action.data,
      };
    default:
      return state;
  }
};

export default authStore;
