import React, { useState } from "react";
import { Button, Container, Form, FormControl } from "react-bootstrap";

import usePasswordProtect from "./helpers/usePasswordProtect";
import { LOGO_URL } from "config/constants";
import styles from "./styles.module.scss";
import Loader from "../../components/common/loader";

export default function PasswordProtection({ children }) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const { isEnable, loading, sendPassword } = usePasswordProtect();

  const onSendPassword = async (e) => {
    e.preventDefault();

    setError(null);
    if (await sendPassword(password)) return;
    setError("You input an invalid project password");
  };

  if (isEnable) return children;

  if (loading) {
    return (
      <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center text-center p-3">
        <Loader />
      </div>
    );
  }

  return (
    <Container className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <img width="140" height="70" src={`${LOGO_URL}@150x0`} alt="logo" />
        </div>
        <Form onSubmit={onSendPassword}>
          <Form.Group className="mb-3">
            <FormControl
              type="password"
              size="lg"
              placeholder="Please, input a project password"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoFocus
            />
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
          </Form.Group>

          <div className={styles.button}>
            <Button type="submit" className={styles.button}>
              Enter
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
}
