const { isSSR } = require("./ssr");

function toggleHtmlScroll() {
  if (!isSSR()) {
    try {
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("overflow-hidden");
    } catch (e) {}
  }
}

function removeOverflowHiddenFromHTML() {
  if (!isSSR()) {
    try {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("overflow-hidden");
    } catch (e) {}
  }
}

function scrollToTop() {
  try {
    const element = document.getElementById("page-base");

    if (element) {
      element.scrollTo(0, 0);
    }
  } catch (e) {}
}

function scrollBaseToTop(router) {
  router.events.on("routeChangeComplete", () => {
    if (!isSSR()) {
      scrollToTop();
    }
  });
}

export {
  toggleHtmlScroll,
  scrollToTop,
  scrollBaseToTop,
  removeOverflowHiddenFromHTML,
};
