import { isSSR } from "../helpers/ssr";
import { isInvalidBrowser } from "../helpers/unsuportedBrowsersHelper";
import { FIREBASE_CONFIG } from "../config/constants";
import ProgressBar from "@badrap/bar-of-progress";
import Router from "next/router";
import { adsReinit } from "../helpers/adsByGoogle";
import { scrollBaseToTop } from "../helpers/domHelpers";

export function init() {
  if (!isSSR()) {
    if (
      window.location.pathname !== "/unsupported-browser/" &&
      isInvalidBrowser
    ) {
      window.location.href = "/unsupported-browser";
    }

    if (!window.safari) {
      try {
        firebase.initializeApp(FIREBASE_CONFIG);
        window.messaging = firebase.messaging();
      } catch (e) {
        console.log(e);
      }
    }
  }

  const progress = new ProgressBar({
    size: 3,
    color: "#1c1757",
    className: "bar-of-progress",
    delay: 100,
  });

  Router.events.on("routeChangeStart", progress.start);
  Router.events.on("routeChangeComplete", progress.finish);
  Router.events.on("routeChangeError", progress.finish);

  if (process.env.gtm) {
    adsReinit(Router);
  }

  if (!isSSR()) {
    window.goToProfile = () => {
      Router.push("/profile");
    };

    scrollBaseToTop(Router);
  }
}
