import { useSelector, useDispatch } from "react-redux";
import {
  AUTH_GET_USER_REQUEST,
  AUTH_GET_USER_RESPONSE,
  AUTH_GET_USER_REJECT,
} from "../reducers/auth";
import { AUTH_API_URL } from "../config/constants";
import { useCallback } from "react";
import { isSSR } from "../helpers/ssr";

const useAuth = () => {
  const { user, unauthorized, isFetching, url } = useSelector(
    (state) => state?.auth
  );
  const dispatch = useDispatch();

  const init = useCallback(() => {
    if (!isSSR()) {
      if (!isFetching && !user && !unauthorized) {
        fetchUser();
      }
    }
  }, [isFetching, unauthorized, user]);

  const fetchUser = () => {
    dispatch({ type: AUTH_GET_USER_REQUEST });
    fetch(
      `${AUTH_API_URL}?redirect_uri=${window.location.origin}/auth/success/`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.url) {
          dispatch({ type: AUTH_GET_USER_REJECT, data: { url: data.url } });
        } else {
          if (!!data.id) {
            dispatch({ type: AUTH_GET_USER_RESPONSE, data: { user: data } });
          }
        }
      });
  };

  return {
    user,
    authUrl: url,
    isFetching,
    fetchUser,
    init,
  };
};

export default useAuth;
