import { combineReducers } from 'redux';
import auth from './auth';
import profile from './profile';
import toasts from './toasts';
import notifications from './notification';
import devices from './devices';

export default combineReducers({
  auth,
  profile,
  notifications,
  toasts,
  devices,
});
