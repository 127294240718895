const initialState = {
  type: "dark",
  message: "",
};

export const THROW_TOAST = "THROW_TOAST";
export const CLEAR_TOASTS = "CLEAR_TOASTS";

const toastsStore = (state = initialState, action) => {
  switch (action.type) {
    case THROW_TOAST:
      return {
        type: action.data.type,
        message: action.data.message,
      };
    case CLEAR_TOASTS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default toastsStore;
