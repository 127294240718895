import { isSSR } from "../helpers/ssr";

const useMemory = () => {
  return {
    set: (key, value) => {
      if (!isSSR()) {
        try {
          window.localStorage.setItem(key, value);
        } catch (e) {}
      }
    },
    get: (key, defaultValue) => {
      if (!isSSR()) {
        try {
          return window.localStorage.getItem(key) || defaultValue;
        } catch (e) {
          return defaultValue;
        }
      }
    },
    remove: (key) => {
      if (!isSSR()) {
        try {
          window.localStorage.removeItem(key);
        } catch (e) {}
      }
    },
  };
};

export default useMemory;
