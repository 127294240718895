import { isSSR } from './ssr';

function adsReinit(router) {
  router.events.on('routeChangeComplete', () => {
    if (!isSSR()) {
      for (let index = 0; index < document.getElementsByClassName('adsbygoogle').length; index++) {
        try {
          (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
        } catch (e) {}
      }
    }
  });
}

export { adsReinit };
