import { useEffect, useState, useCallback } from 'react';
import { AUTH_API_URL, MODE } from '../../../config/constants';
import { isSSR } from '../../../helpers/ssr';

const usePasswordProtect = () => {
  const [isEnable, setIsEnable] = useState(MODE === 'production');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isEnable || isSSR()) return;

    (async () => {
      try {
        setLoading(true);

        const { success } = await fetch(`${AUTH_API_URL}/protection`, {
          credentials: 'include',
        }).then((res) => res.json());

        setLoading(false);
        return setIsEnable(success);
      } catch (err) {}

      setIsEnable(false);
      setLoading(false);
    })();
  }, []);

  const sendPassword = useCallback(async (password) => {
    try {
      const { success } = await fetch(`${AUTH_API_URL}/protection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',

        body: JSON.stringify({ password }),
      }).then((res) => res.json());

      setIsEnable(success);

      if (success) return true;
    } catch (err) {}

    setIsEnable(false);

    return false;
  }, []);

  return { isEnable, loading, sendPassword };
};

export default usePasswordProtect;
