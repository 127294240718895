export const WP_URL = process.env.wordpressDomain;
export const HOST_URL = process.env.hostUrl;
export const PUBLIC_API_URL = process.env.apiPublicUrl;
export const AUTH_API_URL = process.env.apiAuthUrl;
export const API_URL = process.env.apiUrl;
export const SAFARI_PUSH_ID = process.env.safariPushId;
export const ONETRUST_DOMAIN_ID = process.env.onetrustDomainId;

export const SENTRY_DSN = process.env.sentryDsn;
export const SENTRY_ENVIRONMENT = process.env.sentryEnvironment;
export const FIREBASE_CONFIG = process.env.firebaseConfig;
export const FORMIO_ID = process.env.formioId;
export const APPS_URL = "/app";
export const ROKU =
  "https://channelstore.roku.com/details/249723/america's-voice-news";
export const ANDROID =
  "https://play.google.com/store/apps/details?id=network.americasvoice";
export const IPHONE =
  "https://itunes.apple.com/us/app/americas-voice/id1414478547?ls=1&amp;mt=8";
export const APPLE_TV =
  "https://itunes.apple.com/us/app/americas-voice/id1414478547?ls=1&amp;mt=8";
export const FIRE_TV =
  "https://www.amazon.com/Performance-One-Media-Americas-Voice/dp/B07HPGGNPC/ref=sr_1_1?s=mobile-apps&amp;ie=UTF8&amp;qid=1538488296&amp;sr=1-1";
export const APPLE_APP =
  "https://apps.apple.com/us/app/americas-voice/id1414478547?ls=1";
export const FACEBOOK = "https://www.facebook.com/RealAmericasVoice/";
export const TWITTER = "https://twitter.com/RealAmVoice";
export const YOUTUBE =
  "https://www.youtube.com/channel/UCMGZ8pfQHgZ6Yj0-92PMENg";
export const INSTAGRAM = "https://www.instagram.com/realamericasvoice/";

export const PRIVACY_URL = "/privacy";
export const TERMS_URL = "/terms";
export const ABOUT_URL = "/about";
export const CONTACT_URL = WP_URL + "/contact/";

export const PRODUCTION_BUCKET = "https://cdn.americasvoice.news/";
export const STAGING_BUCKET = "https://staging-cdn.americasvoice.news/";

// export const FACEBOOK_APP_ID = "2060667924197196";
export const FACEBOOK_APP_ID = "1026232504920094";
export const INSTAGRAM_NAME = "realamericasvoice";
export const TWITTER_NAME = "RealAmVoice";

export const DEFAULT_AUTHOR = "America's Voice Admin";
export const VERSION = process.env.version;

export const MODE = process.env.mode;
export const HYVOR_TALK_WEBSITE = process.env.hyvorTalkWebsite;

export const CAPTCHA_KEY = "6LdRF1QdAAAAAA6KdDPYem1B7MYSuUsEjoCfMsKq";

export const LOGO_URL =
  "https://cdn.americasvoice.news/static/images/home-logo/logo.png";
export const PREVIEW_URL =
  "https://cdn.americasvoice.news/static/images/live-feed-preloader.png";
export const NOT_FOUND_URL =
  "https://cdn.americasvoice.news/static/images/404.png";
export const DEFAULT_IMG_URL =
  "https://cdn.americasvoice.news/static/images/americas-voices-picture.jpeg";

export const REVALIDATE_TOKEN = process.env.revalidateToken;
export const DEFAULT_SSG_LIMIT = 24;
export const DEFAULT_CATEGORY_ASSETS_LIMIT = 50;
export const DEFAULT_CATEGORY_DAYS_LIMIT = 5;

export const SENTRY_IGNORE_ERRORS = [
  "top.GLOBALS",
  "illegal access",
  "Can't find variable: Intl",
  "originalCreateNotification",
  "Expected an identifier but found '[' instead",
  "canvas.contentDocument",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  "fb_xd_fragment",
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  "conduitPage",
  "Network request failed",
  "out of memory",
  "TypeError: Cancelled",
  "The play() request was interrupted by a new load request.",
  "InvalidStateError: The object is in an invalid state.",
  `can't redefine non-configurable property "userAgent"`,
  "TypeError: SecurityError",
  "TypeError: Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource.",
  "Can't execute code from freed script",
  "Can't find variable: __firefox__",
  "The request timed out.",
  "undefined is not an object (evaluating 'window.__firefox__.reader')",
  "InvalidStateError: The video element does not have a video track or it has not detected a video track yet.",
  "ResizeObserver loop limit exceeded",
  "Operation aborted",
  "window.localStorage is null",
  "Cannot read property 'style' of null",
  "RangeError",
  "NotAllowedError: The request is not triggered by a user activation",
  "NotAllowedError: The video element is processing a Picture-in-Picture request.",
  "Block-scoped declarations (let, const, function, class) not yet supported outside strict mode",
  "InvalidStateError: Failed to read the 'buffered' property from 'SourceBuffer': This SourceBuffer has been removed from the parent media source.",
  "The request timed out.",
  "anulowane",
  "avbruten",
  "Could not connect to the server.",
  "cannot parse response",
  "No error message",
  "Non-Error promise rejection captured with value:",
  "cancelled",
  "IndexSizeError: The index is not in the allowed range.",
  "QuotaExceededError: The quota has been exceeded.",
  "NotSupportedError: The operation is not supported.",
  "Cannot read property 'postMessage' of null",
  "Unexpected end of script",
  "Load failed",
  "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
  "ibFindAllVideos is not defined",
  "Cannot read properties of null (reading 'postMessage')",
  "The video element is not ready to enter the Picture-in-Picture mode.",
  "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",
  "Unexpected EOF",
  "Fullscreen request denied",
  "The Internet connection appears to be offline.",
  "The network connection was lost.",
  "avbrutt",
  "Abgebrochen",
  "ibPauseAllVideos is not defined",
  "Can't find variable: setIOSParameters",
  "Illegal invocation",
  "Push notification prompting has been disabled.",
  "QuotaExceededError",
  "window.appHotStart is not a function",
  "Can't find variable: webkit",
  "WebKit encountered an internal error",
  "Origin https://americasvoice.news is not allowed by Access-Control-Allow-Origin.",
  "Can't find variable: _pcmBridgeCallbackHandler",
  "A server with the specified hostname could not be found.",
  "googlefc is not defined",
  "feature toggles accessed before being set",
  "Unexpected token ':'",
  "Unexpected identifier",
  "Timeout",
  "Not implemented on this platform",
  /cancelled/i,
  /adsbygoogle/i,
  /non-error/i,
  /SecurityError/i,
  /unsupported-browser/g,
  /justthenews/,
  /NotAllowedError/i,
  /AbortError/i,
  /jtn-embed/i,
  /play/,
  /webkit.messageHandlers.processRule.postMessage/,
];

export const SENTRY_DENY_URLS = [
  /graph\.facebook\.com/i,
  /connect\.facebook\.net\/en_US\/all\.js/i,
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  /extensions\//i,
  /^chrome:\/\//i,
  /webappstoolbarba\.texthelp\.com\//i,
  /bing\.com\//i,
  /justthenews/,
  /unsupported-browser/,
];

export const ALLOW_URLS = [
  "localhost",
  "staging.americasvoice.news",
  "americasvoice.news",
  "https://rav.dev-test.pro",
];
