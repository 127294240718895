const initialState = {
  alerts: {
    success: [],
    warning: [],
    info: [],
    error: [],
  },
  confirmationModalOpen: false,
  confirmationModalDialog: {},
  socialConnectModalOpen: false,
  socialConnectModalDialog: {},
  deleteDeviceModalOpen: false,
  proveidersUrl: "",
};

export const SET_PROFILE_ALERT = "SET_PROFILE_ALERT";

export const OPEN_CONFIRMATION_MODAL = "OPEN_CONFIRMATION_MODAL";
export const CLOSE_CONFIRMATION_MODAL = "CLOSE_CONFIRMATION_MODAL";

export const OPEN_SOCIAL_CONNECT_MODAL = "OPEN_SOCIAL_CONNECT_MODAL";
export const CLOSE_SOCIAL_CONNECT_MODAL = "CLOSE_SOCIAL_CONNECT_MODAL";

export const OPEN_DELETE_DEVICE_MODAL = "OPEN_DELETE_DEVICE_MODAL";
export const CLOSE_DELETE_DEVICE_MODAL = "CLOSE_DELETE_DEVICE_MODAL";

export const SET_PROVIDERS_URL = "SET_PROVIDERS_URL";

const profileStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_ALERT:
      return state;
    case OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalOpen: true,
        confirmationModalDialog: action.data,
      };
    case CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalOpen: false,
        confirmationModalDialog: {},
      };
    case OPEN_SOCIAL_CONNECT_MODAL:
      return {
        ...state,
        socialConnectModalOpen: true,
        socialConnectModalDialog: action.data,
      };
    case CLOSE_SOCIAL_CONNECT_MODAL:
      return {
        ...state,
        socialConnectModalOpen: false,
        socialConnectModalDialog: {},
      };
    case OPEN_DELETE_DEVICE_MODAL:
      return {
        ...state,
        deleteDeviceModalOpen: true,
      };
    case CLOSE_DELETE_DEVICE_MODAL:
      return {
        ...state,
        deleteDeviceModalOpen: false,
      };
    case SET_PROVIDERS_URL:
      return {
        ...state,
        proveidersUrl: action.data.url,
      };
    default:
      return state;
  }
};

export default profileStore;
