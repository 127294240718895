export const GRANTED = 'granted';
export const DEFAULT = 'default';
export const DENIED = 'denied';

const initialState = {
  isPushAllow: GRANTED,
  isIphone: false,
  isAsk: false,
};

export const NOTIFICATION_CHANGE = "NOTIFICATION_CHANGE";
export const IPHONE_CHANGE = "IPHONE_CHANGE";
export const ASK_CHANGE = "ASK_CHANGE";

const notificationStore = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_CHANGE:
      return {
        ...state,
        isPushAllow: action.data.isPushAllow,
      };
    case IPHONE_CHANGE:
      return {
        ...state,
        isIphone: action.data.isIphone,
      };
    case ASK_CHANGE:
      return {
        ...state,
        isAsk: action.data.isAsk,
      };

    default:
      return state;
  }
};

export default notificationStore;

