const Bowser = require('bowser');
import { isSSR } from './ssr';

let browser = {};
let browserName;
let isInvalidBrowser = false;

if (!isSSR()) {
  browser = Bowser.getParser(window.navigator.userAgent);
  browserName = browser.getBrowserName();
  isInvalidBrowser = browser.satisfies({
    chrome: '<50',
    edge: '<50',
    safari: '<10',
    firefox: '<53',
    ie: '<12',
    samsung_internet: '<4',
  });
}

module.exports = {
  browser,
  browserName,
  isInvalidBrowser,
};
